import { NavLink as Link } from 'react-router-dom'

import './style.css'

const Error404Route = () => <div className="route route_error-404">
    <div className="route_error-404__error">
        <div className="route_error-404__error-header">404</div>
        <div className="route_error-404__error-description">
            <p>Такой страницы нет на сайте.</p>
            <p><Link to="/">Главная страница</Link></p>
        </div>
    </div>
</div>

export default Error404Route