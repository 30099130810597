import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ScrollToTop from "component/ScrollToTop"

import './index.css'

import Error404Route from 'route/Error404'
import IndexRoute from 'route/Index'
import SuperJobIdentityRoute from 'route/SuperJob/Identity'
import ReUnionRoute from 'route/ReUnion'
import AIPatternsyRoute from 'route/AI/Patterns'


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<IndexRoute />} />
        <Route path="/superjob/" element={<Navigate replace to="/superjob/identity" />} />
        <Route path="/superjob/identity" element={<SuperJobIdentityRoute />} />
        <Route path="/ai/patterns" element={<AIPatternsyRoute />} />
        <Route path="/reunion" element={<ReUnionRoute />} />
        <Route path="*" element={<Error404Route />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <App />
);