import './style.css'
import avatar from './res/avatar.jpg'


const HiSection = () => <div className="section section_hi">
    <img src={avatar} alt="Рома Ватриковский" className="section_hi__avatar" />
    <div className="section_hi__text">
        <p><strong>Привет! Я Рома Ватриковский.</strong></p>
        <p>Помогаю творческим и продуктовым командам делать свою работу качественно, экономно, прозрачно и в срок. Создаю и оптимизирую процессы.</p>
        <p><a href="mailto:roma@vatrikovsky.ru">roma@vatrikovsky.ru</a></p>
    </div>
</div>

export default HiSection