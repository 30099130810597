import logoArtLebedev from './res/artlebedev.svg'
import logoGeekBrains from './res/geekbrains.svg'
import logoPalindrome from './res/palindrome.svg'
import logoSber from './res/sber.svg'
import logoSuperJob from './res/superjob.svg'

import { HashLink } from "react-router-hash-link";
import Fade from 'react-reveal/Fade';

import './style.css'


const WorkSection = () => (
    <div className="section section_work">
        <div className="section_work__header"><strong>Работаю в компаниях и с компаниями:</strong></div>

        {/* В активном поиске */}
        {/* <a href="https://vatrikovsky.notion.site/4345394f91ae4f178778f726fa76e0b8?pvs=4" target="_blank" rel="noreferrer" className="section_work__item section_work__item_search">
            <strong>Статус: в активном поиске</strong>
        </a> */}
        <a href="https://palindrome.media" target="_blank" rel="noreferrer" className="section_work__item section_work__item_palindrome">
            <Fade bottom>
                <img src={logoPalindrome} className="section_work__item-logo section_work__item-logo-palindrome" alt="Палиндром" />
                <div className="section_work__item-info"><strong>Дизайн-директор</strong><br />2023–…</div>
            </Fade>
        </a>


        {/* Суперджоб */}

        <HashLink smooth to="#superjob" className="section_work__item section_work__item_superjob">
            <Fade bottom>
                <img src={logoSuperJob} className="section_work__item-logo section_work__item-logo-superjob" alt="SuperJob" />
                <div className="section_work__item-info"><strong>CPO / Арт-директор</strong><br />2022–2023</div>
            </Fade>
        </HashLink>
        

        {/* Гикбрейнз */}
        <HashLink smooth to="#geekbrains" className="section_work__item section_work__item_geekbrains">
            <Fade bottom>
                <img src={logoGeekBrains} className="section_work__item-logo section_work__item-logo-geekbrains" alt="GeekBrains" />
                <div className="section_work__item-info"><strong>Председатель дипломной комиссии</strong><br />2021–…</div>
            </Fade>
        </HashLink>

        {/* Сбер */}
        <HashLink smooth to="#sber" className="section_work__item section_work__item_sber">
            <Fade bottom>
                <img src={logoSber} className="section_work__item-logo section_work__item-logo-sber" alt="Сбер" />
                <div className="section_work__item-info"><strong>Руководитель направления автоматизации дизайна</strong><br />2021</div>
            </Fade>
        </HashLink>

        {/* Студия Артемия Лебедева */}
        <HashLink smooth to="#artlebedev" className="section_work__item section_work__item_artlebedev">
            <Fade bottom>
                <img src={logoArtLebedev} className="section_work__item-logo section_work__item-logo-artlebedev" alt="Студия Артемия Лебедева" />
                <div className="section_work__item-info"><strong>Ведущий дизайнер</strong><br />2017–2020</div>
            </Fade>
        </HashLink>
    </div>
)

export default WorkSection