import videoWEBM from './res/reunion.webm'
import videoMP4 from './res/reunion.mp4'
import imgHonour from './res/honour.jpg'
import imgLiberty from './res/liberty.jpg'
import imgIndependence from './res/independence.jpg'
import imgCircles from './res/circles.jpg'
import img1 from './res/1.jpg'
import img2 from './res/2.jpg'
import img3 from './res/3.jpg'
import img4 from './res/4.jpg'
import img5 from './res/5.jpg'
import img6 from './res/6.jpg'

import Text from 'component/block/Text'
import ImageChange from 'component/block/ImageChange'
import ImageScroll from 'component/block/ImageScroll'
import Roles from 'component/block/Roles'
import Footer from 'component/Footer'
// import ProjectNavigation from 'component/ProjectNavigation'
import TopNavigation from 'component/TopNavigation'

import { Helmet } from 'react-helmet'

import './style.css'

const ReUnionRoute = () => <div className="route route_project route_reunion">
    <Helmet>
        <title>Айдентика Форума свободных народов Европы</title>
        <meta name="description" content="Совместно с Димой Легостаевым сделали айдентику Форума свободных народов Европы Re:Union." />
    </Helmet>
    <TopNavigation />

    <h2 className="section__header section_reunion__header">Айдентика Re:Union<sup className="section-header__date">2023</sup></h2>

    <Text>
        <p>Совместно с Димой Легостаевым сделали айдентику Форума свободных народов Европы Re:Union.</p>
        <p>Логотип представляет собой трансформацию флага Евросоюза. В оригинальном флаге золотые звезды ассоциируются с привилегиями отдельных «золотых» стран над остальными. В логотипе Форума звезды превращаются в монолитный белый круг, символизирующий равенство участников союза вне зависимости от времени вступления, силы армии или размера экономики.</p>
    </Text>

    <div className="route_reunion__video">
        <video autoplay="" playsinline="" muted="" loop="loop" preload="metadata">
            <source src={videoWEBM}  type="video/webm" />
            <source src={videoMP4}  type="video/mp4" />
        </video>
    </div>

    <Text>
        <p>Белый цвет выбран как цвет свободы, равенства и демократии.</p>
        <p>Для ключевых ценностей Форума придуманы динамичные графические композиции.</p>
    </Text>

    <ImageChange height={.54} start={1.1} end={.3} caption="Для ключевых ценностей Форума придуманы динамичные графические композиции">
        <img src={imgLiberty} alt="Liberty" />
        <img src={imgHonour} alt="Honour" />
        <img src={imgIndependence} alt="Independence" />
    </ImageChange>


    <Text>
        <p>Логотип породил узнаваемый графический образ — «круг свободы». Поиск кругов свободы стал совместной игрой организаторов Форума и аудитории.</p>
    </Text>

    <ImageScroll src={imgCircles} alt="Круги свободы" className="reunion__circles" start={0} end={1} padding={.1} caption="Круги свободы" />

    <Text>
        <p>Форум состоялся 20 июля 2023 в Белграде. Выступили спикеры из Сербии, Франции, Греции, Македонии, США, Гагаузии (Молдавия), Польши, России, Басконии (Испания), Эстонии, Болгарии. Событие вызвало резонанс в <abbr>СМИ</abbr> разных стран.</p>
    </Text>

    <div className="reunion-news">
        <img className="reunion-news__image-item reunion-news__image-item-5" src={img5} alt="Пресса разных стран о Форуме свободных народов Европы-5" />
        <img className="reunion-news__image-item reunion-news__image-item-6" src={img6} alt="Пресса разных стран о Форуме свободных народов Европы-6" />
        <img className="reunion-news__image-item reunion-news__image-item-4" src={img4} alt="Пресса разных стран о Форуме свободных народов Европы-4" />
        <img className="reunion-news__image-item reunion-news__image-item-3" src={img3} alt="Пресса разных стран о Форуме свободных народов Европы-3" />
        <img className="reunion-news__image-item reunion-news__image-item-2" src={img2} alt="Пресса разных стран о Форуме свободных народов Европы-2" />
        <img className="reunion-news__image-item reunion-news__image-item-1" src={img1} alt="Пресса разных стран о Форуме свободных народов Европы-1" />
    </div> 

    <Roles 
        className="route_reunion__roles"
        roles={[
            [
                {
                    title: "Дизайнер",
                    persons: [
                        {
                            name: "Рома Ватриковский"
                        }
                    ]
                },
            ],
            [
                {
                    title: "Дизайнер",
                    persons: [
                        {
                            name: "Дима Легостаев",
                            link: "https://legostaeff.me"
                        }
                    ]
                }
            ],
        ]}
    />

    <Footer theme="dark" />
</div>

export default ReUnionRoute