
import imageGuides from './res/guides.jpg'
import imageNavigation from './res/tower-navigation.jpg'
import imageDesignConf from './res/sber-design-conf.jpg'

import './style.css'


const SberSection = () => (
    <div className="section section_sber" id="sber">
        <h2 className="section__header section_sber__header">В Сбере <sup className="section-header__date">2021</sup></h2>
        <div className="grid-wrapper grid-wrapper_sber">

            {/* СберДизайнКонф-2021 */}
            <div className="section__item section__item_main section_sber__item_main">
                <div className="section-item__image section_sber-item_main__image">
                    <img src={imageDesignConf} alt="СберДизайнКонф-2021" />
                </div>
                <div className="section-item_main__info">
                    <div className="section-item__name section-item_main__name">СберДизайнКонф-2021</div>
                    <div className="section-item__description section-item_main__description">Помог состояться промо-сайту, сайту, рассылкам и прочим штукам для цифровой дизайн-конференции с участием Росса Лавгроува, Якоба Нильсена, Тимура Бекмамбетова, Юрия Гордона и других классных ребят</div>
                </div>
            </div>

            {/* Навигация в башнях Сбера на Кутузовском */}
            <div className="section__item section_sber__item section_sber__item_navigation">
                <div className="section-item__image section_sber-item-navigation__image">
                    <img src={imageNavigation} alt="Навигация в башнях Сбера на Кутузовском" />
                </div>
                <div className="section-item__name">Навигация в башнях Сбера на Кутузовском</div>
                <div className="section-item__description">Сделал непривычное и запутанное здание простым, понятным и уютным</div>
            </div>

            {/* Автоматизация гайдлайнов экосистемы Сбера  */}
            <div className="section__item section_sber__item section_sber__item_guides">
                <div className="section-item__image section_sber-item-guides__image">
                    <img src={imageGuides} alt="Гайдлайны экосистемы Сбера" />
                </div>
                <div className="section-item__name">Автоматизация гайдлайнов экосистемы Сбера</div>
                <div className="section-item__description">Систематизировал и автоматизировал дизайн-систему Сбера и дочерних компаний. Повысил эффективность и консистентность</div>
            </div>
        </div>
    </div>
)

export default SberSection