// Роли
// roles — массив:
// [
//     // Колонка
//     [
//         // Секция
//         {
//             title: "Название группы",
//             persons: [
//                 // Человек
//                 {
//                     name: "Имя Фамилия",
//                     link: "https://site.ru", // опционально
                     
//                 }
//             ],
//         },
//     ],
// ]
// caption — подпись (строка или элемент)

import './style.css'

const Roles = (props) => (
    <div className={(props.className !== undefined ? props.className : '') + ' block_roles'}>
        <h2 className="block_roles__header">Над проектом работали</h2>
        {props.roles.map(column => (
            <div className="block_roles__column">
                {column.map(section => (
                    <div className="block_roles__section">
                        <div className="block_roles__section-title">{section.title}</div>
                        {!!section.persons && section.persons.map(person => (
                            <div className="block_roles__person">
                                {!!person.link
                                    ? <a href={person.link}>{person.name}</a>
                                    : <span>{person.name}</span>
                                }
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        ))}
        {!!props.caption && <div className="block_roles__caption">{props.caption}</div>}
    </div>
)
    
export default Roles