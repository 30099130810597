// Скроллит изображение по горизонтали при скролле страницы
// width — ширина изображения по отношению к ширине экрана (1 = 100%). По умолчанию 2
// start — запас до начала эффекта в процентах от высоты элемента (1 = 100%), насколько показался контейнер. По умолчанию 1 (показался полностью)
// end — запас до конца эффекта в процентах от высоты элемента (1 = 100%), насколько скрылся контейнер. По умолчанию 0 (только начал скрываться)
// padding — отступ от края экрана (влияет на стартовую и конечную позицию по горизонтали) в процентах от высоты элемента (1 = 100%)

import React, { useState, useEffect, useRef } from 'react'

import './style.css'

const ImageScroll = (props) => {

    const containerRef = useRef(null)
    const [scrollTop, setScrollTop] = useState(0)
    const [left, setLeft] = useState(0)

    // События параллакса
    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop)
        }
        window.addEventListener('scroll', onScroll)
        const height = containerRef.current.offsetHeight
        const top = containerRef.current.offsetTop
        const start = props.start !== undefined ? props.start * height : height
        const end = props.end !== undefined ? props.end * height : 0
        const width = props.width !== undefined ? props.width * 100 : 2 * 100

        // Начало и конец эффекта
        const effectStartPosition = top - window.innerHeight + start
        const effectEndPosition = top + end

        // Отступ слева
        let progress = (scrollTop - effectStartPosition) / (effectEndPosition - effectStartPosition)
        if (progress < 0) progress = 0
        if (progress > 1) progress = 1
        setLeft((100 - width) * progress)

        return () => window.removeEventListener('scroll', onScroll)
    }, [scrollTop, props.start, props.end, props.width])
    
    // Верстка
    return (
        <div 
            className={props.className + ' block_image-scroll'}
            ref={containerRef}
            style={{
                paddingLeft: (props.padding !== undefined ? props.padding : 0) * 100 + "%",
                paddingRight: (props.padding !== undefined ? props.padding : 0) * 100 + "%",
            }}
        >
            <img src={props.src} alt={props.alt} style={{width: (props.width !== undefined ? props.width : 2) * 100 + "%", left: left + "%"}} />
            {!!props.caption && <div className="block_image__caption">{props.caption}</div>}
        </div>
    )
}
    
export default ImageScroll