
import imgCover from './res/cover.jpg'

import './style.css'

const TGUSection = () => (
    <div className="section section_tgu">
        <div className="section__item section_tgu__item">
            <div className="section-item__description">
                <h2 className="section-item__title">Цикл лекций для ТГУ</h2>
                <p className="section-item__text">Записал цикл лекций «Как реализовывать идеи в крупных компаниях» для Томского Государственного Университета</p>
                <div className="section-item__date">2022–2023</div>
            </div>
            <div className="section-item__image">
                <img src={imgCover} alt="Цикл лекция для ТГУ: как реализовывать идеи в крупных компаниях" />
            </div>            
        </div>
    </div>
)

export default TGUSection