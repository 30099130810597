// Изображение
// height — высота блока по отношению к ширине = 1. По умолчанию .5625 (16:9)
// caption — элемент подписи
// src — элемент изображения
// alt — HTML-свойство

import classNames from 'classnames'
import { useState, useEffect } from 'react'

import './style.css'

const Image = (props) => {
    const [data, setData] = useState(props.data)
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        setData(data.map((item, index) => {
            item.active = index === activeIndex
            item.style = { backgroundImage: `url(${item.img})` }
            if (item.imgSize) item.style.backgroundSize = item.imgSize
            return item
        }))
    }, [activeIndex])

    return (
        !!props.data &&
        <div 
            className={classNames(
                props.className,
                'block_image-variants',
            )}
        >
            {props.header && <h2 className="block_image-variants__header">{props.header}</h2>}
            <div 
                className='block_image-variants__content' 
                style={{
                    paddingBottom: (props.height || .5625) * 100 + "%"
                }}
            >
                {data.map((item, itemIndex) => (
                    <div 
                        key={itemIndex} 
                        style={item.style} 
                        className={classNames(
                            'block_image-variants__content-item',
                            {'block_image-variants__content-item_active': item.active},
                        )}
                    >
                        {!!item.linkList && item.linkList.map((link, linkIndex) => (
                            <a 
                                key={linkIndex} 
                                href={link.url} 
                                className='block_image-variants__content-item-link'
                                download
                            >
                                {link.title}
                            </a>
                        ))}
                    </div>
                ))}
            </div>
            <div className='block_image-variants__controls'>
                {data.map((item, itemIndex) => (
                    <div 
                        key={itemIndex} 
                        className={classNames(
                            'block_image-variants__control-item',
                            {'block_image-variants__control-item_active': item.active},
                        )}
                        onClick={() => {setActiveIndex(itemIndex)}}
                    >
                        <div style={{backgroundImage: `url(${item.img})`}} className='block_image-variants__control-item-image' />
                        {!!item.previewName && (
                            <div className='block_image-variants__control-item-text'>{item.previewName}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
    
export default Image