import imgPattern1 from '../res/imperial-pattern-1.jpg'
import imgPattern1HD from '../res/imperial-pattern-1.png'
import imgWallpapers1 from '../res/imperial-wallpapers-1.png'
import imgPattern2 from '../res/imperial-pattern-2.jpg'
import imgPattern2HD from '../res/imperial-pattern-2.png'
import imgWallpapers2 from '../res/imperial-wallpapers-2.png'
import imgPattern3 from '../res/imperial-pattern-3.jpg'
import imgPattern3HD from '../res/imperial-pattern-3.png'
import imgWallpapers3 from '../res/imperial-wallpapers-3.png'
import imgPattern4 from '../res/imperial-pattern-4.jpg'
import imgPattern4HD from '../res/imperial-pattern-4.png'
import imgWallpapers4 from '../res/imperial-wallpapers-4.png'
import imgPattern5 from '../res/imperial-pattern-5.jpg'
import imgPattern5HD from '../res/imperial-pattern-5.png'
import imgWallpapers5 from '../res/imperial-wallpapers-5.png'
import imgPattern6 from '../res/imperial-pattern-6.jpg'
import imgPattern6HD from '../res/imperial-pattern-6.png'
import imgWallpapers6 from '../res/imperial-wallpapers-6.png'
import imgPattern7 from '../res/imperial-pattern-7.jpg'
import imgPattern7HD from '../res/imperial-pattern-7.png'
import imgWallpapers7 from '../res/imperial-wallpapers-7.png'
import imgPattern8 from '../res/imperial-pattern-8.jpg'
import imgPattern8HD from '../res/imperial-pattern-8.png'
import imgWallpapers8 from '../res/imperial-wallpapers-8.png'
import imgPattern9 from '../res/imperial-pattern-9.jpg'
import imgPattern9HD from '../res/imperial-pattern-9.png'
import imgWallpapers9 from '../res/imperial-wallpapers-9.png'
import imgPattern10 from '../res/imperial-pattern-10.jpg'
import imgPattern10HD from '../res/imperial-pattern-10.png'
import imgWallpapers10 from '../res/imperial-wallpapers-10.png'
import imgPattern11 from '../res/imperial-pattern-11.jpg'
import imgPattern11HD from '../res/imperial-pattern-11.png'
import imgWallpapers11 from '../res/imperial-wallpapers-11.png'


const imperial = [
    {
        img: imgPattern1,
        linkList: [
            {
                url: imgPattern1HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers1,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern2,
        linkList: [
            {
                url: imgPattern2HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers2,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern3,
        linkList: [
            {
                url: imgPattern3HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers3,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern4,
        linkList: [
            {
                url: imgPattern4HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers4,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern5,
        linkList: [
            {
                url: imgPattern5HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers5,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern6,
        linkList: [
            {
                url: imgPattern6HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers6,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern7,
        linkList: [
            {
                url: imgPattern7HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers7,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern8,
        linkList: [
            {
                url: imgPattern8HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers8,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern9,
        linkList: [
            {
                url: imgPattern9HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers9,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern10,
        linkList: [
            {
                url: imgPattern10HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers10,
                title: "Скачать обои",
            },
        ],
    },
    {
        img: imgPattern11,
        linkList: [
            {
                url: imgPattern11HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers11,
                title: "Скачать обои",
            },
        ],
    },
    // {
    //     img: imgPattern12,
    //     linkList: [
    //         {
    //             url: imgPattern12,
    //             title: <span>Скачать <abbr>.PNG</abbr></span>,
    //         },
    //         {
    //             url: imgWallpapers12,
    //             title: "Скачать обои",
    //         },
    //     ],
    // },
    // {
    //     img: imgPattern13,
    //     linkList: [
    //         {
    //             url: imgPattern13,
    //             title: <span>Скачать <abbr>.PNG</abbr></span>,
    //         },
    //         {
    //             url: imgWallpapers13,
    //             title: "Скачать обои",
    //         },
    //     ],
    // },
    // {
    //     img: imgPattern14,
    //     linkList: [
    //         {
    //             url: imgPattern14,
    //             title: <span>Скачать <abbr>.PNG</abbr></span>,
    //         },
    //         {
    //             url: imgWallpapers14,
    //             title: "Скачать обои",
    //         },
    //     ],
    // },
]

export default imperial