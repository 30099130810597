import './style.css'

import image1 from './res/1.jpeg'
import image2 from './res/2.jpeg'
import image3 from './res/3.jpeg'
import image4 from './res/4.jpeg'
import image5 from './res/5.jpeg'
import image6 from './res/6.jpeg'

const InstagramSection = () => (
    <div className="section section_instagram">
        <div className="section__item section_instagram__item">
            <div className="section-item__description">
                <h2 className="section-item__title">Леттеринг</h2>
                <p className="section-item__text">Год ежедневно рисовал <a href="https://instagram.com/vatrikovsky" rel="noreferrer" target="_blank">и выкладывал буквы в «Инстаграм»</a>. А как перестал, так и «Инстаграм» запретили в России</p>
                <div className="section-item__date">2020–2022</div>
            </div>
            <div className="section-item__image">
                <img className="section_instagram__image-item section_instagram__image-item-2" src={image2} alt="Леттеринг-2" />
                <img className="section_instagram__image-item section_instagram__image-item-3" src={image3} alt="Леттеринг-3" />
                <img className="section_instagram__image-item section_instagram__image-item-4" src={image4} alt="Леттеринг-4" />
                <img className="section_instagram__image-item section_instagram__image-item-5" src={image5} alt="Леттеринг-5" />
                <img className="section_instagram__image-item section_instagram__image-item-6" src={image6} alt="Леттеринг-6" />
                <img className="section_instagram__image-item section_instagram__image-item-1" src={image1} alt="Леттеринг-1" />
            </div>            
        </div>
    </div>
)

export default InstagramSection