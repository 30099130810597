import logo from './res/logo.svg'
import imgStart from './res/start.png'
import imgCourses from './res/courses.png'
import imgRealty from './res/realty.png'
import imgInterface1 from './res/interface-1.png'
import imgInterface2 from './res/interface-2.png'
import imgInterface3 from './res/interface-3.png'
import imgLandingBrowser from './res/landing-browser.png'
import imgLandingIconBag from './res/landing-icon-bag.png'
import imgLandingIconCoin from './res/landing-icon-coin.png'
import imgLandingIconFunnel from './res/landing-icon-funnel.png'
import imgLandingIconGlasses from './res/landing-icon-glasses.png'
import imgLandingIconHeart from './res/landing-icon-heart.png'
import imgLandingIconLetter from './res/landing-icon-letter.png'
import imgLandingIconPhone from './res/landing-icon-phone.png'
import imgLandingWoman from './res/landing-woman.png'
import imgProduct from './res/product.png'
import imgITStart from './res/it-start-2.jpg'
import imgElevatorLeftDoor from './res/elevator-left-door.png'
import imgElevatorRightDoor from './res/elevator-right-door.png'
import imgElevatorInner from './res/elevator-inner.png'
import imgElevatorOuter from './res/elevator-outer.png'

import { NavLink } from 'react-router-dom'

import './style.css'

const SuperJobSection = () => (
    <div className="section section_superjob" id="superjob">
        <h2 className="section__header section_superjob__header">В Суперджобе <sup className="section-header__date">2022–…</sup></h2>

        <div className="grid-wrapper grid-wrapper_superjob">
            {/* Айдентика (главная) */}
            <NavLink to="/superjob/identity" className="section__item section__item_main section_superjob__item_main">
                <img src={logo} className="section-item__image" alt="Логотип SuperJob" />
                <div className="section-item__name">Новая айдентика старейшего джоб-борда России</div>
            </NavLink>

            {/* Айдентика и продукты суббрендов */}
            <div to="#" className="section__item section_superjob__item section_superjob__item_subbrands">
            
                <div className="section-item__image">
                <img src={imgCourses} alt="SuperJob Курсы" />
                <img src={imgStart} alt="SuperJob Старт" />
                <img src={imgRealty} alt="SuperJob Недвижимость" />
                </div>
                <div className="section-item__name">Айдентика и продукты<br />суббрендов</div>
            </div>

            {/* 1000 и 1 улучшение интерфейсов */}
            <div to="#" className="section__item section_superjob__item section_superjob__item_interface">
                <div className="section-item__image">
                    <img src={imgInterface1} alt="Интерфейс сайта SuperJob" />
                    <img src={imgInterface2} alt="Интерфейс сайта SuperJob" />
                    <img src={imgInterface3} alt="Интерфейс сайта SuperJob" />
                </div>
                <div className="section-item__name">Тысяча и одно улучшение интерфейсов</div>
            </div>

            {/* 8 рекламных кампаний */}
            <div className="section__item section_superjob__item section_superjob__item_elevator">
                <div className="section-item__image">
                    <img src={imgElevatorInner} className="item_elevator__inner" alt="" />
                    <img src={imgElevatorLeftDoor} className="item_elevator__left-door" alt="" />
                    <img src={imgElevatorRightDoor} className="item_elevator__right-door" alt="" />
                    <img src={imgElevatorOuter} className="item_elevator__outer" alt="" />
                </div>
                <div className="section-item__name">8 рекламных кампаний</div>
            </div>

            {/* Продукт */}
            {/* <div className="section__item section_superjob__item section_superjob__item_product">
                <div className="section-item__image">
                    <img src={imgProduct} alt="Метрики продукта выросли" />
                </div>
                <div className="section-item__name">Рост ключевых метрик продукта</div>
            </div> */}

            {/* Шаблоны лендингов */}
            <div className="section__item section_superjob__item section_superjob__item_landings">
                <div className="section-item__image">
                    <img src={imgLandingBrowser} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_browser" />
                    <div className="item_landings__icon-list">
                        <img src={imgLandingIconGlasses} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_glasses" />
                        <img src={imgLandingWoman} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_woman" />
                        <img src={imgLandingIconLetter} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_letter" />
                        <img src={imgLandingIconBag} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_bag" />
                        <img src={imgLandingIconCoin} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_coin" />
                        <img src={imgLandingIconFunnel} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_funnel" />
                        <img src={imgLandingIconHeart} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_heart" />
                        <img src={imgLandingIconPhone} alt="Шаблоны лендингов SuperJob" className="section_superjob__landing_icon_phone" />
                    </div>
                </div>
                <div className="section-item__name">Шаблоны лендингов</div>
            </div>

            {/* А еще */}
            <div className="section__item section_superjob__item section_superjob__item_other">
                <div className="section-item__name">А еще пре&shy;зен&shy;та&shy;ции, пос&shy;ты в соцсе&shy;тях, электрон&shy;ный жур&shy;нал, куча вся&shy;ко&shy;го мер&shy;ча, иллюстра&shy;ции, стен&shy;ды на меро&shy;при&shy;я&shy;ти&shy;ях, поздрав&shy;ле&shy;ния, блан&shy;ки и про&shy;чее.</div>
            </div>
        </div>

        {/* IT Start */}
        <div className="section__item section_it_start__item">
            <div className="section-item__description">
                <h2 className="section-item__title">Старт в IT</h2>
                <p className="section-item__text">Выступил на двухдневном форуме, рассказал о старте карьеры в IT и ролях в продуктовой разработке.</p>
                <p className="section-item__text">Форум прошел в павильоне ВДНХ «Умный город» при поддержке Департамента информационных технологий.</p>
                <div className="section-item__date">2023</div>
            </div>
            <div className="section-item__image">
                <img src={imgITStart} alt="Старт в IT" />
            </div>            
        </div>
    </div>
)

export default SuperJobSection