// import imgHeaderBackground from './res/header-background.jpg'
import imgMain from './res/biology-pattern-3.jpg'

import dataImperial from './data/imperial.js'
import dataBiology from './data/biology.js'


import Text from 'component/block/Text'
import Cover from 'component/block/Cover'
import ImageVariants from 'component/block/ImageVariants'

import Footer from 'component/Footer'
// import ProjectNavigation from 'component/ProjectNavigation'
import TopNavigation from 'component/TopNavigation'

import { Helmet } from 'react-helmet';

import './style.css'

const AIPatternsRoute = () => <div className="route route_project route_ai-patterns">
    <Helmet>
        <title>Нейропаттерны</title>
        <meta name="description" content="Генерирую нейросетями паттерны невероятной красоты и раздаю бесплатно без регистрации и смс." />
    </Helmet>
    
    <TopNavigation />

    {/* <h2 className="section__header section_ai__header" style={{background: `url(${imgHeaderBackground}) no-repeat` }}>Нейроводство <sup className="section-header__date">2023–…</sup></h2>
    <ProjectNavigation itemList={[
        {
            title: 'Манифест',
            // href: '/superjob/identity',
        },
        {
            title: 'Паттерны',
            href: '/ai/patterns',
        },
    ]}/> */}
    
    <Cover img={imgMain} height={.001} className="ai-patterns__cover" title="Нейропаттерны" />

    <Text>
        <p>Генерирую нейросетями паттерны невероятной красоты и раздаю бесплатно без регистрации и <abbr>смс</abbr>.</p>
        <p>Паттерны в растровом формате <abbr>PNG</abbr>, но в хорошем качестве — подойдут для цифровых носителей и печати в небольшом размере.</p>
        <p>Если вы используете их для получения коммерческой выгоды — укажите явным образом мое авторство. Если продукт позволяет, поставьте ссылку на этот сайт. Это обязательно.</p>
    </Text>

    <ImageVariants data={dataImperial} height={.4} header={<span>Современные русские узоры <sup>{dataImperial.length}</sup></span>} className="ai-patterns__imperial" />
    <ImageVariants data={dataBiology} height={.4} header={<span>Биология <sup>{dataBiology.length}</sup></span>} className="ai-patterns__biology" />
    
    <Footer />
</div>

export default AIPatternsRoute