import imageMoscowCrossroads from './res/moscow-crossroads.jpg'
import imageElets from './res/elets.jpg'
import imageGorkyPark from './res/gorky-park.jpg'
import imageOhuennyStickerpack from './res/ohuenny-stickerpack.jpg'
import imageVdnh from './res/vdnh.jpg'

import './style.css'


const ArtLebedevSection = () => (
    <div className="section section_artlebedev" id="artlebedev">
        <h2 className="section__header section_artlebedev__header">В Студии <sup className="section-header__date">2017–2020</sup></h2>
        <div className="grid-wrapper grid-wrapper_artlebedev">

            {/* Оформление московских перекрестков */}
            <a href="https://www.artlebedev.ru/moscow/crossroads/" className="section__item section__item_main section_artlebedev__item_main" target="_blank" rel="noreferrer">
                <div className="section-item__image section_artlebedev-item_main__image">
                    <img src={imageMoscowCrossroads} alt="Оформление московских перекрестков" />
                </div>
                <div className="section-item_main__info">
                    <div className="section-item__name section-item_main__name">Оформление московских перекрестков</div>
                    <div className="section-item__description section-item_main__description">Помог родному городу обзавестись красивыми, понятными и безопасными перекрестками</div>
                </div>
            </a>

            {/* Охуенный стикерпак */}
            <a href="https://www.artlebedev.ru/stickers/ohuenny/" className="section__item section_artlebedev__item section_artlebedev__item_stickerpack" target="_blank" rel="noreferrer">
                <div className="section-item__image section_artlebedev-item-stickerpack__image">
                    <img src={imageOhuennyStickerpack} alt="Охуенный стикерпак" />
                </div>
                <div className="section-item__name">Охуенный стикерпак</div>
                <div className="section-item__description">Придумал и собрал популярный стикерпак для «Телеграма»</div>
            </a>

            {/* Навигация в Парке Горького */}
            <a href="https://www.artlebedev.ru/gorky-park/navigation/" className="section__item section_artlebedev__item section_artlebedev__item_pg" target="_blank" rel="noreferrer">
                <div className="section-item__image section_artlebedev-item-pg__image">
                    <img src={imageGorkyPark} alt="Навигация в Парке Горького" />
                </div>
                <div className="section-item__name">Навигация в Парке Горького</div>
                <div className="section-item__description">Приложил руку к разработке навигации в главном парке страны</div>
            </a>

            {/* Бренд-платформа Ельца */}
            <a href="https://www.artlebedev.ru/yelets/" className="section__item section_artlebedev__item section_artlebedev__item_elets" target="_blank" rel="noreferrer">
                <div className="section-item__image section_artlebedev-item-elets__image">
                    <img src={imageElets} alt="Бренд-платформа Ельца" />
                </div>
                <div className="section-item__name">Бренд-платформа Ельца</div>
                <div className="section-item__description">Помог городу обрести свою айдентику</div>
            </a>

            {/* Навигация в Парке Горького */}
            <a href="https://www.artlebedev.ru/vdnh/navigation/" className="section__item section_artlebedev__item section_artlebedev__item_vdnkh" target="_blank" rel="noreferrer">
                <div className="section-item__image section_artlebedev-item-vdnkh__image">
                    <img src={imageVdnh} alt="Навигация в Парке Горького" />
                </div>
                <div className="section-item__name">Навигация в парке <abbr>ВДНХ</abbr></div>
                <div className="section-item__description">Автоматизировал масштабный навигационный проект</div>
            </a>

            {/* Еще */}
            <a href="https://www.artlebedev.ru/vatrikovsky/" className="section__item section_artlebedev__item section_artlebedev__item_other" target="_blank" rel="noreferrer">
                <div className="section_artlebedev-item_other__info">
                    <div className="section_artlebedev-item_other__text">и еще</div>
                    <div className="section_artlebedev-item_other__number">23</div>
                    <div className="section_artlebedev-item_other__text">проекта</div>
                </div>
            </a>
        </div>
    </div>
)

export default ArtLebedevSection