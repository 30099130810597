import { NavLink } from "react-router-dom"

import './style.css'

const TopNavigation = (props) => <div className={(!!props.className ? props.className : "") + " top-navigation"}>
    <div className="top-navigation__inner">
        <NavLink to="/" className="top-navigation__item">&larr; Главная</NavLink>
    </div>
</div>

export default TopNavigation