import { NavLink } from 'react-router-dom'

import videoWEBM from 'route/ReUnion/res/reunion.webm'
import videoMP4 from 'route/ReUnion/res/reunion.mp4'
import './style.css'

const ReUnionSection = () => (
    <div className="section section_reunion">
        <div className="grid-wrapper grid-wrapper_reunion">
            <NavLink to="/reunion" className="section__item section__item_main section_reunion__item_main">
                <video autoplay="" playsinline="" muted="" loop="loop" preload="metadata">
                    <source src={videoWEBM}  type="video/webm" />
                    <source src={videoMP4}  type="video/mp4" />
                </video>
                {/* <img src={logo} className="section-item__image" alt="Логотип ReUnion" /> */}
                <div className="section-item__name">Айдентика Форума свободных народов Европы</div>
            </NavLink>
        </div>
    </div>
)

export default ReUnionSection