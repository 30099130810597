import imgNumber from './res/number.svg'
import imgStudent from './res/student.png'

import './style.css'

const GeekBrainsSection = () => (
    <div className="section section_geekbrains" id="geekbrains">
        <h2 className="section__header section_geekbrains__header">В Гикбрейнс <sup className="section-header__date">2021—…</sup></h2>
        <div className="section__item section__item_main section_geekbrains__item_main">
            <div className="section_geekbrains__item-main-inner">
                <img className="section_geekbrains__image" src={imgNumber} alt="350+" />
                <div className="section_geekbrains__lead">студентов выпустил из «Гикбрейнс»</div>
                <div className="section_geekbrains__text">В качестве председателя выпускной комиссии вместе с коллегами разобрал и оценил выпускные работы нескольких сотен студентов факультета веб-дизайна</div>
            </div>
            <img src={imgStudent} className="section_geekbrains__student" alt="Студент «Гикбрейнс»"/>
        </div>
    </div>
)

export default GeekBrainsSection