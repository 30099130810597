import imgBIT from './res/bit.jpg'
import imgPizza from './res/pizza.jpg'

import './style.css'

const BITSection = () => (
    <div className="section section_bit">
        <div className="grid-wrapper grid-wrapper_bit">

            {/* BIT */}
            <div className="section__item section_bit__item section_bit__item_bit">            
                <div className="section-item__image">
                    <img src={imgBIT} alt="BIT — цифровой форум ЭМИТ" />
                </div>
                <div className="section-item__name section-item_bit__name">Смарт-толк о дизайне на форуме <abbr>РАНХиГС</abbr></div>
                <div className="section-item__text section-item_bit__text">Поговорил о сложностях профессии с Марией Карауловой, Вероникой Потаповой, Денисом Вылегжаниным и Артемом Буевым</div>
                <div className="section-item__date section-item_bit__date">декабрь 2022</div>
            </div>

            {/* Пицца */}
            <div className="section__item section_bit__item section_bit__item_pizza">            
                <div className="section-item__image">
                    <img src={imgPizza} alt="Пицца" />
                    <div className="section-item__caption">…а еще рассказал студентам Школы <abbr>IT</abbr>-коммуникаций <abbr>РАНХиГС</abbr> о навыках, востребованных в <abbr>IT</abbr>, на примере пиццы</div>
                </div>
            </div>
        </div>
    </div>
)

export default BITSection