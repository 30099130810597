import './style.css'

import AISection from './section/AI'
import ArchiveSection from './section/Archive'
import ArtLebedevSection from './section/ArtLebedev'
import BITSection from './section/BIT'
import GeekBrainsSection from './section/GeekBrains'
import HiSection from './section/Hi'
import InstagramSection from './section/Instagram'
import ReUnionSection from './section/ReUnion'
import SberSection from './section/Sber'
import SideProjectsSection from './section/SideProjects'
import SuperJobSection from './section/SuperJob'
import TGUSection from './section/TGU'
import WorkSection from './section/Work'

import Footer from 'component/Footer'
import { Helmet } from 'react-helmet'


const IndexRoute = () => <div className="route route_index">
    <Helmet>
        <title>Рома Ватриковский</title>
        <meta name="description" content="Создаю и оптимизирую процессы в творческих командах. Занимаюсь интерфейсами, айдентикой, навигацией. Консультирую, веду лекции и блоги." />
    </Helmet>
    <HiSection />
    <WorkSection />
    <ReUnionSection />
    <SuperJobSection />
    <AISection />
    <TGUSection />
    <SideProjectsSection />
    <GeekBrainsSection />
    <BITSection />
    <SberSection />
    <InstagramSection />
    <ArtLebedevSection />
    <ArchiveSection />
    <Footer />
</div>

export default IndexRoute