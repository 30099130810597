// Текст
// Содержимое передается абзацами (<p>...</p>)

import './style.css'

const Text = (props) => (
    <div className={(props.className !== undefined ? props.className : '') + ' block_text'}>
        <div className="block_text__inner">
            {props.children}
        </div>
    </div>
)
    
export default Text