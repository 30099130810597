import imgPattern1 from '../res/biology-pattern-1.jpg'
import imgPattern1HD from '../res/biology-pattern-1.png'
import imgWallpapers1 from '../res/biology-wallpapers-1.png'
import imgPattern2 from '../res/biology-pattern-2.jpg'
import imgPattern2HD from '../res/biology-pattern-2.png'
import imgWallpapers2 from '../res/biology-wallpapers-2.png'
import imgPattern3 from '../res/biology-pattern-3.jpg'
import imgPattern3HD from '../res/biology-pattern-3.png'
import imgWallpapers3 from '../res/biology-wallpapers-3.png'

const biology = [
    {
        img: imgPattern1,
        imgSize: "85%",
        linkList: [
            {
                url: imgPattern1HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers1,
                title: "Скачать обои",
            },
        ],
        previewName: 'Птицы и ягоды'
    },
    {
        img: imgPattern2,
        imgSize: "55%",
        linkList: [
            {
                url: imgPattern2HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers2,
                title: "Скачать обои",
            },
        ],
        previewName: 'Плоды и рыбы'
    },
    {
        img: imgPattern3,
        imgSize: "55%",
        linkList: [
            {
                url: imgPattern3HD,
                title: <span>Скачать <abbr>.PNG</abbr></span>,
            },
            {
                url: imgWallpapers3,
                title: "Скачать обои",
            },
        ],
        previewName: 'Волки и листья'
    },
]

export default biology