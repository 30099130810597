
import { NavLink } from 'react-router-dom'

import imagePattern from './res/biology-pattern-3.jpg'
import './style.css'


const AISection = () => (
    <div className="section section_ai" style={{backgroundImage: `url(${imagePattern})`}}>
        <NavLink to="/ai/patterns">
            <h2 className="section__header section_ai__header">Нейропаттерны <sup className="section-header__date">2023</sup></h2>
        </NavLink>
    </div>
)

export default AISection