import './style.css'

const ArchiveSection = () => (
    <div className="section section_archive">
        <h2 className="section__header section_archive__header">Когда-то давным давно…</h2>
        <div className="grid-wrapper grid-wrapper_archive">
            <a href="/audiolinch/" className="section__item section_archive__item">
                <div className="section-item__name">Аудиолинч</div>
                <div className="section-item__description">Написал рецензии на полсотни музыкальных композиций</div>
                <div className="section-item__date">2017–2019</div>
            </a>
            <a href="/blog/" className="section__item section_archive__item">
                <div className="section-item__name">Блог о музыке</div>
                <div className="section-item__description">Драфтово рассказал о системе восприятия музыки. Ушел копить силы на второй заход</div>
                <div className="section-item__date">2017–2019</div>
            </a>
            <a href="https://soundstudio.moscow/" className="section__item section_archive__item">
                <div className="section-item__name">Soundstudio.moscow</div>
                <div className="section-item__description">Год поработал звукорежиссером в студии звукозаписи с друзьями</div>
                <div className="section-item__date">2016</div>
            </a>
        </div>
    </div>
)

export default ArchiveSection