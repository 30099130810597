import imgMain from './res/main.svg'
import imgLogo from './res/logo.svg'
import imgLogoMain from './res/logo-main.jpg'
import imgLogoNY from './res/logo-ny.jpg'
import imgLogoIdea from './res/logo-idea.jpg'
import imgLogoHome from './res/logo-home.jpg'
import imgTypo from './res/typo.jpg'
import imgIcons from './res/icons.png'
import imgFunnel from './res/funnel.jpg'
import imgSocial from './res/social.jpg'
import imgPhones from './res/phones.jpg'
import imgZoom1 from './res/zoom-1.jpg'
import imgZoom2 from './res/zoom-2.jpg'
import imgZoom3 from './res/zoom-3.jpg'
import imgPhoto from './res/photo.jpg'

import Image from 'component/block/Image'
import Text from 'component/block/Text'
import ImageChange from 'component/block/ImageChange'
import ImageScroll from 'component/block/ImageScroll'
import Roles from 'component/block/Roles'
import Footer from 'component/Footer'
// import ProjectNavigation from 'component/ProjectNavigation'
import TopNavigation from 'component/TopNavigation'

import { Helmet } from 'react-helmet';

import './style.css'

const SuperJobIdentityRoute = () => <div className="route route_project route_superjob-identity">
    <Helmet>
        <title>Новая айдентика «Суперджоба»</title>
        <meta name="description" content="Вместе с командой «Суперджоба» перебрали двигатель машины на ходу и создали новый фирменный стиль компании: современный, контрастный, красивый." />
    </Helmet>
    <TopNavigation />
     <h2 className="section__header section_superjob__header">В Суперджобе <sup className="section-header__date">2022–…</sup></h2>
    {/* <ProjectNavigation itemList={[
        {
            title: 'Айдентика',
            href: '/superjob/identity',
        },
        {
            title: 'Продукт',
            // href: '/superjob/product',
        },
        {
            title: 'Суббренды',
            // href: '/superjob/subbrands',
        },
        {
            title: 'Лендинги',
            // href: '/superjob/landings',
        },
        {
            title: 'Реклама',
            // href: '/superjob/ad',
        },
    ]}/> */}
    <Image src={imgMain} alt="Новая айдентика «Суперджоба»" height={.48}  fullscreen className="superjob-identity__cover" />

    <Text>
        <p>Мы с командой «Суперджоба» перебрали двигатель машины на ходу и создали новый фирменный стиль компании.</p>
        <p>Логотип обрел новые контрастные цвета, формы букв стали качественнее, а сам знак — собраннее.</p>
    </Text>
    <Image src={imgLogo} alt="Новый логотип SuperJob" height={.101} />

    <Text>
        <p>Сердце в логотипе символизирует мэтч работодателя и соискателя, а так же подчеркивает заботу бренда о людях и социальную направленность.</p>
        <p>В дополнительных версиях логотипа в зависимости от контекста на месте сердца размещаются подходящие иллюстрации.</p>
    </Text>
    <ImageChange height={.66} start={.75} end={.1}>
        <img src={imgLogoMain} alt="Логотип с объемным сердцем" />
        <img src={imgLogoNY} alt="Новогодний логотип" />
        <img src={imgLogoHome} alt="Логотип с домиком" />
        <img src={imgLogoIdea} alt="Логотип с лампочкой" />
    </ImageChange>

    <Text>
        <p>Акцидентная типографика с комбинированными начертаниями фирменного шрифта делает материалы бренда узнаваемыми даже без логотипа на макете.</p>
    </Text>
    <Image src={imgTypo} alt="Акцидентная типографика" caption="Для большего акцента отдельные слова выделяются цветом" height={.722} />

    <Text>
        <p>Для быстрого создания маркетинговых материалов мы разработали сет фирменных трехмерных иллюстраций.</p>
    </Text>
    <ImageScroll src={imgIcons} alt="Трехмерные иллюстрации" start={.1} end={.9} caption={<div style={{marginTop: "-1rem"}}>Бонусом собрали из иллюстраций <a href="https://t.me/addstickers/SuperJob_2023">стикерпак для «Телеграма»</a></div>} />
    <Image src={imgFunnel} alt="Композиция из трехмерных иллюстраций" caption="Иллюстрации собираются в красивые объемные композиции" fullscreen height={1} />
    <ImageScroll src={imgSocial} alt="Оформление ВК" className="superjob-identity__vk" start={0} end={1} padding={.1} caption="Оформление группы «Суперджоба» в ВК" />
    <Image src={imgPhoto} alt="Композиция из иллюстраций и фотографий" caption="Иллюстрации отлично сочетаются с фотографиями людей. Даже стоковые материалы начинают играть новыми красками" fullscreen />

    <Text>
        <p>Фирменное зеленое сердце органично смотрится на самых разных носителях, поддерживая бренд даже без размещения логотипа. Сочетание цвета и символа настолько яркое, что сохраняет узнаваемость в любых формах и проявлениях.</p>
    </Text>
    <Image src={imgPhones} alt="Корпоративные фоны для созвонов" caption="Фирменные обои сделаны с помощью нейросети" height={.63} />
    <ImageChange height={.66} caption="Корпоративные фоны для созвонов: строгие и смелые" start={.9} end={.1} minDelta={.2}>
        <img src={imgZoom3} alt="Корпоративные фоны для созвонов" />
        <img src={imgZoom2} alt="Корпоративные фоны для созвонов" />
        <img src={imgZoom1} alt="Корпоративные фоны для созвонов" />
    </ImageChange>

    <Text>
        <p>Правила использования фирменного стиля собраны в компактное и удобное руководство.</p>
    </Text>

    <Roles 
        className="route_superjob-identity__roles"
        caption="…и другие участники дружной команды «Суперджоба»."
        roles={[
            [
                {
                    title: "Арт-директор",
                    persons: [
                        {
                            name: "Рома Ватриковский"
                        }
                    ]
                },
            ],
            [
                {
                    title: "Дизайнер",
                    persons: [
                        {
                            name: "Дима Легостаев",
                            link: "https://legostaeff.me"
                        }
                    ]
                }
            ],
            [
                {
                    title: "Менеджер",
                    persons: [
                        {
                            name: "Александр Афонин"
                        }
                    ]
                },
            ],
            [
                {
                    title: "Редактор",
                    persons: [
                        {
                            name: "Магомед Рамадан"
                        }
                    ]
                }
            ]
        ]}
    />

    <Footer theme="dark" />
</div>

export default SuperJobIdentityRoute