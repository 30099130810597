// Меняет изображения по ходу прокрутки
// height — высота блока по отношению к ширине = 1. По умолчанию .5625 (16:9)
// start — запас до начала эффекта в процентах от высоты элемента (1 = 100%), насколько показался контейнер. По умолчанию 1 (показался полностью)
// end — запас до конца эффекта в процентах от высоты элемента (1 = 100%), насколько скрылся контейнер. По умолчанию 0 (только начал скрываться)
// minDelta — минимальная дельта прокрутки между сменой изображений, в процентах от высоты элемента (1 = 100%). По умолчанию 0.1

import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'

import './style.css'

const ImageChange = (props) => {

    const containerRef = useRef(null)
    const [scrollTop, setScrollTop] = useState(0)
    const [activeElementIndex, setActiveElementIndex] = useState(1)

    // События параллакса
    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop)
        }
        window.addEventListener('scroll', onScroll)
        const height = containerRef.current.offsetHeight
        const top = containerRef.current.offsetTop
        const start = props.start !== undefined ? props.start * height : height
        const end = props.end !== undefined ? props.end * height : 0
        const minDelta = props.minDelta !== undefined ? props.minDelta * height : .1 * height
        const totalElements = props.children.length
        const totalPoints = totalElements - 1

        // Начало и конец эффекта
        let effectStartPosition = top - window.innerHeight + start
        let effectEndPosition = top + end
        
        // Дельта
        if (Math.abs(effectEndPosition - effectStartPosition) < totalPoints * minDelta) {
            if (effectEndPosition > effectStartPosition) {
                effectEndPosition += minDelta / 2
                effectStartPosition -= minDelta / 2
            }
            else {
                effectEndPosition -= minDelta / 2
                effectStartPosition += minDelta / 2
            }
        }

        // Точки смены изображений
        const pointArray = []
        for (let i = 0; i < totalPoints; i++ ) {
            pointArray.push(effectStartPosition + (effectEndPosition - effectStartPosition) / (totalPoints - 1) * i)
        }
        pointArray.push(effectEndPosition)

        // Активное изображение
        let newActiveElement = 1
        pointArray.forEach(point => {
            if (scrollTop > point) newActiveElement++
        })
        setActiveElementIndex(newActiveElement)

        return () => window.removeEventListener('scroll', onScroll)
    }, [scrollTop, props.children.length, props.start, props.end, props.minDelta])
    
    // Верстка
    let c = classNames(
        props.className,
        'block_image-change',
        'show' + activeElementIndex,
    )
    return (
        <div 
            className={c}
            style={{paddingBottom: (props.height !== undefined ? props.height : .5625) * 100 + "%"}} 
            ref={containerRef}
        >
            {props.children}
        </div>
    )
}
    
export default ImageChange

