// Изображение
// height — высота блока по отношению к ширине = 1. По умолчанию .5625 (16:9)
// title — заголовок
// img — фоновое изображение

import classNames from 'classnames'

import './style.css'

const Cover = (props) => {
    return (
        <div 
            className={classNames(
                props.className,
                'block_cover',
            )}
            style={{
                backgroundImage: `url(${props.img})`,
                paddingBottom: (props.height || .5625) * 100 + "%"
            }}
        >
            {!!props.title && <div className="block_cover__title">{props.title}</div>}
        </div>
    )
}
    
export default Cover