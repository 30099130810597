

import imgIntersection1 from './res/intersection-1.svg'
import imgIntersection2 from './res/intersection-2.svg'
import imgIntersection3 from './res/intersection-3.svg'
import imgIntersection4 from './res/intersection-4.svg'
import imgIntersection5 from './res/intersection-5.svg'
import imgIntersectionText from './res/intersection-text.svg'

import imgTg1 from './res/tg-1.png'
import imgTg2 from './res/tg-2.png'
import imgTgPlane from './res/tg-plane.svg'

import './style.css'

const SideProjectsSection = () => (
    <div className="grid-wrapper grid-wrapper__sideprojects">
        <div className="section section_telegram">
            <h2 className="section__header section_telegram__header">Телеграммы</h2>
            <div className="section__inner section_telegram__inner">
                <a href="https://t.me/vatrikovsky_thinks" target="_blank" rel="noreferrer" className="section_telegram__channel">
                    <div className="section_telegram__channel-image">
                        <img src={imgTg1} alt="Ватриковский думает" />
                    </div>
                    <div className="section_telegram__channel-info">
                        <div className="section_telegram__channel-name">Ватриковский думает</div>
                        <div className="section_telegram__channel-description">Рассказываю про управление людьми и собой в видеокругах</div>
                    </div>
                </a>
                <a href="https://t.me/vatrikovsky_writes" target="_blank" rel="noreferrer" className="section_telegram__channel">
                    <div className="section_telegram__channel-image">
                        <img src={imgTg2} alt="Ватриковский пишет" />
                    </div>
                    <div className="section_telegram__channel-info">
                        <div className="section_telegram__channel-name">Ватриковский пишет</div>
                        <div className="section_telegram__channel-description">Нерегулярные посты про все подряд</div>
                    </div>
                </a>
                <img src={imgTgPlane} className="section_telegram__plane" alt="" />
            </div>
        </div>
        <a href="https://intersection.team" target="_blank" rel="noreferrer" className="section section_intersection">
            <h2 className="section__header section_intersection__header">Коллабы <sup className="section-header__date">2021–…</sup></h2>
            <div className="section__inner section_intersection__inner">
                <div className="section__image section_intersection__image">
                    <div className="section_intersection__image-sign">
                        <img src={imgIntersection1} alt="" />
                        <img src={imgIntersection2} alt="" />
                        <img src={imgIntersection3} alt="" />
                        <img src={imgIntersection4} alt="" />
                        <img src={imgIntersection5} alt="" />
                    </div>
                    <div className="section_intersection__image-text">
                        <img src={imgIntersectionText} alt="Творческое объединение «Пересечение»" />
                    </div>
                </div>
                <div className="section__text section_intersection__text">
                    Делаю штуки в коллабах с крутыми ребятами: подкасты, каналы, продукты
                </div>
            </div>
        </a>
    </div>
)

export default SideProjectsSection