// Изображение
// height — высота блока по отношению к ширине = 1. По умолчанию .5625 (16:9)
// caption — элемент подписи
// src — элемент изображения
// alt — HTML-свойство

import classNames from 'classnames'

import './style.css'

const Image = (props) => (
    <div 
        className={classNames(
            props.className,
            'block_image',
            {'block_image_fullscreen': props.fullscreen}
        )}
    >
        <div 
            className='block_image__img'
            style={{paddingBottom: (props.height || .5625) * 100 + "%"}} 
        >
            <img src={props.src} alt={props.alt} />
        </div>
        {!!props.caption && <div className="block_image__caption">{props.caption}</div>}
    </div>
)
    
export default Image